import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      default: () => import('@/pages/Home.vue')
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    components: {
      default: () => import('@/pages/PrivacyPolicy.vue')
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
